define("a11y-announcer/services/announcer", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _service.default.extend({
    message: 'has loaded',
    announceMessage: null,
    announceTone: 'polite',
    announce: function (message, tone) {
      this.setProperties({
        announceMessage: message,
        announceTone: tone
      });
    }
  });
});