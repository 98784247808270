define("a11y-announcer/components/route-announcer", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "@ember/component", "a11y-announcer/templates/components/route-announcer"], function (_exports, _computed, _object, _service, _component, _routeAnnouncer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    announcer: (0, _service.inject)('announcer'),
    layout: _routeAnnouncer.default,
    classNames: ['screen-reader'],
    attributeBindings: ['ariaLive:aria-live'],
    ariaLive: (0, _object.computed)('tone', function () {
      return this.get('announcer').get('announceTone') || 'polite';
    }),
    tone: (0, _computed.alias)('announcer.announceTone'),
    message: (0, _computed.alias)('announcer.announceMessage')
  });
});