/* eslint no-undef: 0 */
window.deprecationWorkflow = window.deprecationWorkflow || {};
window.deprecationWorkflow.config = {
  workflow: [
    { handler: "silence", matchMessage: "The `initialize` method for Application initializer 'keyset-pagination-initializer' should take only one argument - `App`, an instance of an `Application`." },
    { handler: "silence", matchMessage: "Using the global version of DS is deprecated. Please either import the specific modules needed or `import DS from 'ember-data';`." },
    { handler: "silence", matchMessage: "Using `ApplicationInstance.container.lookup` is deprecated. Please use `ApplicationInstance.lookup` instead." },
    { handler: "silence", matchMessage: "Using the injected `container` is deprecated. Please use the `getOwner` helper to access the owner of this object and then call `lookup` instead." },
    { handler: "silence", matchMessage: /modified inside the didInsertElement hook/ },
    { handler: "silence", matchMessage: "Pushing a record into a BelongsToReference is deprecated"},
    { handler: "silence", matchMessage: "Using the globals resolver is deprecated. Use the ember-resolver package instead." }
  ]
}
