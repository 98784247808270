define("a11y-announcer/templates/components/route-announcer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "c4i8b327",
    "block": "[[[1,[30,0,[\"message\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "a11y-announcer/templates/components/route-announcer.hbs",
    "isStrictMode": false
  });
});