define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "access-provided-by-panel": "__access-provided-by-panel__fdad2",
    "article-loading-box": "__article-loading-box__b2efe",
    "article-loading-unavailable-article-help": "__article-loading-unavailable-article-help__841e8",
    "article-not-found-screen": "__article-not-found-screen__0969a",
    "article-options": "__article-options__cef31",
    "browzine-logo": "__browzine-logo__fd737",
    "centered-menu": "__centered-menu__d9027",
    "discover-more": "__discover-more__cee13",
    "error-screen": "__error-screen__34a83",
    "format-chooser": "__format-chooser__d4c2d",
    "handle-request-panel/confirm-decline-request": "__handle-request-panel__confirm-decline-request__d1e5f",
    "handle-request-panel": "__handle-request-panel__4d08d",
    "help-link": "__help-link__468fe",
    "holdings-tool-article-not-available": "__holdings-tool-article-not-available__5244b",
    "journal-box": "__journal-box__f7d75",
    "journal-cover": "__journal-cover__771d8",
    "language-list": "__language-list__fc30a",
    "libkey-wayf-panel": "__libkey-wayf-panel__bfbe7",
    "library-logo": "__library-logo__f3f48",
    "loading-indicator": "__loading-indicator__0d2b3",
    "loading-screen": "__loading-screen__e1b04",
    "locale-chooser": "__locale-chooser__43ae4",
    "login-information": "__login-information__51214",
    "logo-and-tagline-panel": "__logo-and-tagline-panel__44a8f",
    "not-sure-panel": "__not-sure-panel__25e12",
    "problematic-domain-panel": "__problematic-domain-panel__b3054",
    "request-article-panel": "__request-article-panel__9a4c9",
    "request-confirmation-panel": "__request-confirmation-panel__25dba",
    "request-from-another-library": "__request-from-another-library__1e70e",
    "splash-panel": "__splash-panel__f1079",
    "unpaywall-article-preview": "__unpaywall-article-preview__aaae3",
    "workflow-steps-progress": "__workflow-steps-progress__5400c"
  };
});